@keyframes pulse {
  0% {
    background-color: var(--bar-background);
  }
  100% {
    background-color: var(--background-sidebar);
  }
}

.include_pulse_animation {
  animation: pulse 1s infinite;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');

* {
  font-family: 'Open Sans', sans-serif !important;
}

:root {
  --code-editor-bg: rgb(255, 255, 255);
  --background: #DBDBD4;
  --backgroundModal: #DBDBD4;
  --background-secondary: #f1f1f1;
  --background-sidebar-item: #fff;
  --text-primary: #555;
  --text-secondary: #333;
  --text-light: #666;
  --agent: #dcf8c6;
  --bar-background: #EEE;
  --background-department: #696969;
  --text-department: #fff;
  --background-company: #696969;
  --text-company: #444444;
  --text-modal: #2c2c2c;
  --input-background: #fff;
  --border-color: #e5e5e5;
  --border-color-hr: rgb(202, 202, 202);
  --border-color-modal: #ddd;
  --background-item-hover: #f2f2f2;
  --icon-color: #6E6E6E;
  --background-chat: #E5DCD5;
  --chat-incoming: #fff;
  --chat-send: #dcf8c6;
  --chat-reply-incoming: #cec7c7;
  --chat-reply-send: #b8c6ac;
  --background-menu-hover: #fff;
  --background-unread: #09d261;
  --background-new: #d78d32;
  --btn-login: #110f74;
  --btn-login-hover: #17159a;
  --input-bg-color: #cfd0c9;
  --input-bg-modal-color: #cfd0c9;
  --btn-submit: #008F75;
  --btn-submit-hover: #00C29F;

  --btn-registerNew: #5A9600;
  --btn-registerNew-hover: #12c52a61;

  --icon-active: #005c4b;
  --overlay-rgb: 11, 20, 26;
  --border-image-company: #dedddd;

  --webhook-background: #fff;

  --background-admin-edit: #fff;
  --background-admin-edit-border: #ffffff1c;
  --background-admin-edit-color: #333;
  --background-admin-default: #E5DCD6;

  --wrapper-input-admin-background: #fff;
  --wrapper-input-admin-color: #333;
  --wrapper-input-admin-border: #bebebe;

  --button-bg-admin-active: #09d261;
  --button-bg-admin-hover: #ccc;
  --profile-marker: #cbced0;
  --profile-img-marker: rgba(171, 168, 169, 0.8);
  --icon-color: #444444;
  --export-button: red;
  --menu-background: #363636ee;
}

[data-theme='dark'] {
  --menu-background: red;
  --export-button: red;
  --code-editor-bg: black;
  --background: #0a0f14;
  --backgroundModal: #1f2327;
  --background-secondary: #111b21;
  --background-sidebar-item: #111b21;
  --text-primary: #e9edef;
  --text-secondary: #fff;
  --text-light: #9952527f;
  --agent: #005c4b;
  --bar-background: #212c34;
  --background-department: #005c4b;
  --text-department: #f1f1f1;
  --background-company: #005c4b;
  --text-company: #f1f1f1;
  --text-modal: #fff;
  --input-background: #2a3942;
  --border-color: rgba(134, 150, 160, 0.15);
  --border-color-hr: rgba(134, 150, 160, 0.15);
  --border-color-modal: #ffffff21;
  --background-item-hover: #202c33;
  --icon-color: #8e8e8e;
  --background-chat: #0b131b;
  --chat-incoming: #202c33;
  --chat-send: #005c4b;
  --chat-reply-incoming: #1d2e58;
  --chat-reply-send: #02332a;
  --background-menu-hover: #202c33;
  --background-unread: #00a884;
  --background-new: #a86200;
  --icon-active: #005c4b;
  --border-image-company: transparent;

  --webhook-background: #dddddd;

  --background-admin-edit: #283840;
  --background-admin-edit-border: #ffffff1c;
  --background-admin-edit-color: #fff;
  --background-admin-default: #212D34;

  --wrapper-input-admin-background: #202c34;
  --wrapper-input-admin-color: #fff;
  --wrapper-input-admin-border: #ffffff1c;

  --btn-registerNew: #008f5f;
  --btn-registerNew-hover: #369c44;
  --input-bg-modal-color: #cfd0c9;
  --input-bg-color: #9d9c9c3c;
  --profile-marker: #27343D;
  --profile-img-marker: rgba(30, 42, 49, 0.8);
  --icon-color: #bfbfbf;

}